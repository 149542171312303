<template>
  <div>
    <div class="overmask" :style="returnaCor('alphaLogin', false, true)"></div>
    <div id="Login" class="container">
      <div v-if="carregando" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>

      <b-row class="mt-5 text-center" v-if="!isMobile()">
        <b-col>
          <div class="logo">
            <img :src="logo" style="max-width: 90%" />
          </div>
          <div class="alert alert-warning">
            Aplicativo disponível para acesso somente por Celular ou Tablet
          </div>
        </b-col>
      </b-row>
      <div v-else>
        <b-row class="mt-5 text-center" v-if="erroConexao != ''">
          <b-col>
            <div class="logo">
              <img :src="logo" style="max-width: 90%" />
            </div>
            <div class="alert alert-warning">{{ erroConexao }}</div>
            <b-button @click="buscaDadosEmpresa()">Tentar Novamente</b-button>
          </b-col>
        </b-row>
        <b-row v-else class="mt-5">
          <b-col class="text-center">
            <div class="logo">
              <img :src="logo" style="max-width: 90%" />
            </div>
            <div v-if="!form.idEmpresa">
              <b-form-group
                id="input-group-2"
                label="Associação"
                class="mt-3"
                label-for="input-2"
              >
                <b-form-select
                  v-model="form.idEmpresa"
                  :options="empresas"
                  name="Associação"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="container mt-4" v-if="form.formSelecionado == 0">
              <a
                v-if="utils.verificaEmpresaBemProtege(idEmpresa)"
                v-b-modal.modalCotacao
                class="btn btn-block mt-5 mb-3 btn-dark"
                :style="returnaCor('botaoLogin', true, false)"
                >Quero Cotar</a
              >

              <a
                v-else
                :href="direcionaCotacaoOnline(false)"
                class="btn btn-block mt-5 mb-3 btn-dark"
                :style="returnaCor('botaoLogin', true, false)"
                >Quero Cotar</a
              >

              <b-button
                type="submit"
                class="btn-block mt-2 mb-4"
                variant="dark"
                @click="selecionaTab(1)"
                :style="returnaOnlyBorder()"
                >Sou segurado</b-button
              >
            </div>
            <b-form
              v-if="form.formSelecionado == 1"
              @submit.stop.prevent="onSubmit"
              class="container"
            >
              <div>
                <b-form-group
                  v-if="!validateRouteCode"
                  id="input-group-2"
                  label
                  label-for="input-2"
                >
                  <p class="text-white text-left text-sm">
                    Preencha aqui seu email.
                  </p>
                  <b-form-input
                    type="text"
                    class="form-control"
                    v-model="email"
                    placeholder="joao@email.com.br"
                    id="email"
                    name="Email"
                    :class="{ 'is-invalid': emailError }"
                  ></b-form-input>
                </b-form-group>

                <!-- <b-form-group
                  v-if="!validateRouteCode"
                  id="input-group-3"
                  label
                  label-for="input-2"
                >
                  <the-mask
                    type="text"
                    class="form-control mt-4"
                    v-model="cellphone"
                    :placeholder="'(XX) XXXXX-XXXX'"
                    :mask="['(##) #####-####']"
                    id="cellphone"
                    name="cellphone"
                    :class="{ 'is-invalid': cellphoneError }"
                  ></the-mask>
                </b-form-group> -->

                <div v-if="validateRouteCode">
                  <b-form-group id="input-group-2" label-for="input-2">
                    <div id="eyeButton" @click="exibirSenha()">
                      <i :class="iconIputSenha" aria-hidden="true"></i>
                    </div>
                    <b-form-input
                      :type="`${tipoInputSenha}`"
                      id="validationCode"
                      v-model="validationCode"
                      name="Codigo de acesso"
                      placeholder="Código de acesso"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <b-button
                type="submit"
                class="btn-block mt-3"
                variant="dark"
                :style="returnaCor('botaoLogin', true, false)"
                >Entrar</b-button
              >
            </b-form>

            <b-modal
              id="modalCotacao"
              title="Prezado(a),"
              :header-text-variant="'light'"
              header-class="headerModalCotacao"
              ok-variant="info"
              :ok-title="
                checked ? 'Buscar minha cotação' : 'Criar nova cotação'
              "
              :ok-disabled="checked && btnDisabled"
              ok-only
              @ok="
                checked
                  ? direcionaCotacaoExistente()
                  : direcionaCotacaoOnline(true)
              "
            >
              <div class="px-3 pt-2">
                <p>Já está sendo atendido por um consultor?</p>

                <b-form-radio
                  v-model="checked"
                  name="check-button"
                  switch
                  size="lg"
                  :value="false"
                  >Não, desejo criar uma nova cotação.</b-form-radio
                >
                <b-form-radio
                  class="mt-4"
                  v-model="checked"
                  name="check-button"
                  switch
                  size="lg"
                  :value="true"
                  >Sim, já possuo cotação em andamento.</b-form-radio
                >
                <div v-if="checked" class="placaChassiInput">
                  <hr />
                  <h6>Digite a placa ou chassi do seu veículo:</h6>
                  <div class="w-100">
                    <the-mask
                      mask="AAA-#XX#"
                      style="text-transform: uppercase"
                      v-model="placa"
                      placeholder="Placa"
                      name="Placa"
                      :masked="true"
                      v-validate="{ length: 8 }"
                      :state="validateState('Placa')"
                      class="form-control"
                      @keyup.native="keymonitorPlacaChassi('placa')"
                    />
                    <span
                      v-if="cotacaoEncontrada && placa"
                      class="cotacaoEncontradaTrue"
                      >Cotação encontrada</span
                    >
                    <span
                      v-if="!cotacaoEncontrada && placa && placa.length == 8"
                      class="cotacaoEncontradaFalse"
                      >Cotação não encontrada</span
                    >
                  </div>
                  OU
                  <div class="w-100">
                    <b-form-input
                      placeholder="Chassi"
                      style="text-transform: uppercase"
                      v-model="chassi"
                      name="Chassi"
                      v-validate="{ length: 17 }"
                      maxlength="17"
                      :state="validateState('Chassi')"
                      :class="validateClass('Chassi')"
                      @keyup.native="keymonitorPlacaChassi('chassi')"
                    />
                    <span
                      v-if="cotacaoEncontrada && chassi"
                      class="cotacaoEncontradaTrue"
                      >Cotação encontrada</span
                    >
                    <span
                      v-if="!cotacaoEncontrada && chassi && chassi.length == 17"
                      class="cotacaoEncontradaFalse"
                      >Cotação não encontrada</span
                    >
                  </div>
                </div>
              </div>
            </b-modal>
          </b-col>
        </b-row>
      </div>

      <rodape></rodape>
    </div>
  </div>
</template>

<script>
import locale from './../locale';
import service from '../services';
import utils from '../services/utils';
import Rodape from './../shared/rodape.vue';
import dotenv from 'dotenv';
import { WHATSAPP, SMS } from './../shared/global';
import {
  saveIdEmpresa,
  saveIdEmpresaDefault,
  saveIdUsuarioInitialLogin,
  saveListEmpresas,
} from './../services/multiple-empresa';
import { decryptData } from '../services/decryptData';
import Axios from 'axios';
dotenv.config();

export default {
  name: 'DashBoard',
  props: {
    msg: String,
  },
  data() {
    return {
      email: '',
      emailError: false,
      cellphone: null,
      cellphoneError: false,
      validateRouteCode: false,
      validationCode: null,

      utils: utils,
      cotacaoEncontrada: false,
      chaveProposta: null,
      dadosProposta: null,
      placaValida: null,
      chassiValido: null,
      btnDisabled: true,
      placa: null,
      chassi: null,
      form: {
        placa: null,
        email: null,
        emailConsultor: null,
        senha: null,
        idEmpresa: null,
        nf: null,
        formSelecionado: 0,
        cpfcnpj: null,
      },
      checked: null,
      tipoInputSenha: 'password',
      iconIputSenha: 'fa fa-eye',
      idEmpresa: this.$route.query.idEmpresa.replace('/', ''),

      consultorEsqueceuSenha: false,
      consultorEncontrado: false,
      dadosConsultorRecuperados: {
        nome: null,
        email: null,
        cpf: null,
        celular: null,
      },
      isCarroZero: false,
      empresas: [],
      empresasAux: [],
      erroConexao: '',
      carregando: false,
      logo: '',
      trocouLogo: false,
      value: 80,
      max: 100,
      pathRedirect: null,
      temaCor: {
        botaoLogin: '#000000',
        alphaLogin: '#000000',
        botaoFormularios: '#000000',
      },
      contando: false,
      contador: 45,
      desabilitar: false,
      sentWhatsapp: 0,
      selectedMethodSend: WHATSAPP,
      WHATSAPP: WHATSAPP,
      SMS: SMS,
      MODAL_RECUPERAR: 'modal-recuperar-senha',
    };
  },
  components: {
    rodape: Rodape,
  },
  created: function () {
    this.atualizaManifest();
    this.$watch('form', this.colocaImagem, {
      deep: true,
    });
  },
  methods: {
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.email)) {
        this.emailError = true;
        utils.showToast(
          this.$bvToast,
          'Por favor, preencha em e-mail válido',
          'danger',
        );
        return;
      }
      this.emailError = false;
    },
    validateCellphone() {
      const cellphonePattern = this.cellphone.length === 11;
      if (!cellphonePattern) {
        this.cellphoneError = true;
        utils.showToast(
          this.$bvToast,
          'Por favor, preencha um número de celular válido',
          'danger',
        );
        return;
      }
      this.cellphoneError = false;
    },
    formatCellphone() {
      const cellphone = this.cellphone.replace(/\D/g, '');
      if (cellphone.length !== 11) {
        this.cellphoneError = true;
        utils.showToast(
          this.$bvToast,
          'Por favor, preencha um número de celular válido',
          'danger',
        );
        return;
      }
      this.cellphone = `(${cellphone.slice(0, 2)}) ${cellphone.slice(
        2,
        7,
      )}-${cellphone.slice(7)}`;
    },
    validaEmailConsultor() {
      this.carregando = true;
      service
        .post('Login', 'valida-email-consultor', this.form)
        .then((res) => {
          this.carregando = false;
          if (!res.data.length) {
            utils.showToast(
              this.$bvToast,
              'Não foi encontrado nenhum consultor com email informado',
              'warning',
            );
            return;
          }
          this.consultorEsqueceuSenha = false;
          const msg = this.getMessage({ data: res.data[0] });
          utils.showToast(this.$bvToast, msg, 'success');
          this.contagemRegressiva();
        })
        .catch((e) => {
          this.carregando = false;
          utils.showToast(this.$bvToast, e, 'danger');
        });
    },
    isMobile() {
      if (process.env.VUE_APP_ENV == 'DEV') {
        return true;
      }
      if (
        /Mobile|mini|Fennec|Android|iP(ad|od|hone)/i.test(navigator.userAgent)
      ) {
        return true;
      } else {
        return false;
      }
    },
    colocaImagem() {
      if (this.trocouLogo == false) {
        if (this.form.idEmpresa) {
          if (this.empresasAux.length > 0) {
            this.logo = this.empresasAux.find(
              (x) => x.idEmpresa == this.form.idEmpresa,
            ).logo;
            this.trocouLogo = true;
          }
        }
      }
    },
    selecionaTab(valor) {
      this.form.senha = '';
      this.form.formSelecionado = valor;
      if (valor == 1) {
        document.body.className = 'associado';
        this.verificarSeContando();
      }
      if (valor == 2) document.body.className = 'consultor';
      if (this.form.idEmpresa == 29) {
        document.body.className = document.body.className + ' carvip';
      }

      if (utils.verificaEmpresaBemProtege(this.form.idEmpresa)) {
        document.body.className = document.body.className + ' bp';
      }
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },

    async onSubmit() {
      this.carregando = true;
      this.desabilitar = true;

      try {
        if (!this.validateRouteCode) {
          if (!this.email) {
            this.showMessage('Por favor, preencha o email.', 'danger');
            return;
          }

          this.validateEmail();
          if (this.emailError) {
            return;
          }

          await this.sendActivationCode();
          return;
        }

        if (!this.validationCode) {
          this.showMessage('Por favor, preencha o código de acesso.', 'danger');
          return;
        }

        await this.activateUserCode();
      } catch (error) {
        this.showMessage(
          'Ocorreu um erro no processo de autenticação.',
          'danger',
        );
      } finally {
        this.carregando = false;
        this.desabilitar = false;
      }
    },

    async sendActivationCode() {
      const COOLDOWN_TIME_IN_MINUTES = 5 * 60 * 1000;
      const lastSentTime = localStorage.getItem('lastSentTime');
      const isWithinCooldown =
        lastSentTime && Date.now() - lastSentTime < COOLDOWN_TIME_IN_MINUTES;

      if (isWithinCooldown) {
        const timeRemaining = Math.ceil(
          (COOLDOWN_TIME_IN_MINUTES - (Date.now() - lastSentTime)) / 1000 / 60,
        );
        this.showMessage(
          `Por favor, aguarde ${timeRemaining} minutos antes de enviar outro e-mail ou verifique sua caixa de entrada.`,
          'info',
        );
        this.validateRouteCode = true;
        return;
      }

      try {
        const url = 'https://apiv2.bbonebrasil.com.br';
        const response = await Axios.post(`${url}/authentication/code/send`, {
          activationMethod: this.email,
        });

        if (response) {
          this.showMessage(
            'O código de acesso foi enviado ao seu email. Aguarde um instante.',
            'success',
          );
          this.validateRouteCode = true;
          localStorage.setItem('lastSentTime', Date.now());
        }
      } catch {
        this.showMessage('Erro ao enviar o código de ativação.', 'danger');
      }
    },

    async activateUserCode() {
      try {
        const url = 'https://apiv2.bbonebrasil.com.br';
        const responseCode = await Axios.post(
          `${url}/authentication/code/activate`,
          {
            activationMethod: this.email,
            code: this.validationCode,
          },
        );
        const isExpired = this.isCodeExpired(responseCode);
        if (isExpired) return;

        this.handleSuccessfulActivation(responseCode.data);
      } catch {
        this.showMessage('Erro ao ativar o código.', 'danger');
      }
    },

    isCodeExpired(responseCode) {
      const isUnauthorized = responseCode.status === 401;
      const isInvalidCode = responseCode.status >= 300;

      if (isUnauthorized) {
        this.showMessage(
          'Seu código expirou, por favor digite seus dados novamente.',
          'danger',
        );
        this.validateRouteCode = false;
        return true;
      }

      if (isInvalidCode) {
        this.showMessage(
          'Código de acesso inválido. Por favor digite novamente.',
          'danger',
        );
        return true;
      }

      return false;
    },

    handleSuccessfulActivation(data) {
      const { token, redirect, dadosRes } = data;

      if (token) {
        localStorage.setItem('user-token', token);
        saveIdUsuarioInitialLogin(token);
        saveIdEmpresa(token);
      }

      const empresasAssociado = dadosRes?.empresas || dadosRes?.id_empresa;
      const idEmpresaDefaultAssociado =
        dadosRes?.idEmpresaDefault || dadosRes?.id_empresa;

      saveListEmpresas(empresasAssociado);
      saveIdEmpresaDefault(idEmpresaDefaultAssociado);

      if (dadosRes) {
        localStorage.setItem('dadosRes', JSON.stringify(dadosRes));
      }

      this.$router.push({ path: redirect });
    },

    showMessage(message, variant = 'danger') {
      utils.showToast(this.$bvToast, message, variant);
    },

    direcionaCotacaoOnline(isRedirect) {
      const urlOrigem = window.location.origin;

      //Para uso em QA
      if (urlOrigem.includes('qa.app')) {
        const novaUrl = urlOrigem.replace('qa.app', 'qa.adm');
        const redirect = `${novaUrl}/novaProposta/${this.form.idEmpresa}?insideApp=true`;
        if (isRedirect) window.location = redirect;
        return redirect;
      }

      //Para uso em ambiente local caso a porta do APP seja 8080 e a do ADM seja 8081
      if (urlOrigem.includes('localhost:8080')) {
        const novaUrl = urlOrigem.replace('8080', '8081');
        const redirect = `${novaUrl}/novaProposta/${this.form.idEmpresa}?insideApp=true`;
        if (isRedirect) window.location = redirect;
        return redirect;
      }

      //Para uso em ambiente local caso a porta do APP seja 8081 e a do ADM seja 8080
      if (urlOrigem.includes('localhost:8081')) {
        const novaUrl = urlOrigem.replace('8081', '8080');
        const redirect = `${novaUrl}/novaProposta/${this.form.idEmpresa}?insideApp=true`;
        if (isRedirect) window.location = redirect;
        return redirect;
      }

      //Para uso em produção
      const novaUrl = urlOrigem.replace('app', 'adm');
      const redirect = `${novaUrl}/novaProposta/${this.form.idEmpresa}?insideApp=true`;
      if (isRedirect) window.location = redirect;
      return redirect;
    },
    atualizaManifest() {
      this.form.idEmpresa = this.$route.query.idEmpresa.replace('/', '');
      const manifest = document.getElementById('manifest');
      manifest.href = `/manifest_${this.form.idEmpresa}.json`;
    },
    returnaOnlyBorder() {
      return `
      border:2px solid #FFF;
      background-color: transparent;
      color:#FFF
      `;
    },
    returnaCor(chave, comSombra, backgroundTransparent) {
      return utils.returnCor(
        this.temaCor,
        chave,
        comSombra,
        backgroundTransparent,
      );
    },
    buscaDadosEmpresa() {
      return new Promise((resolve) => {
        document.body.className = 'home';
        this.carregando = true;
        this.form.idEmpresa = this.$route.query.idEmpresa.replace('/', '');
        if (this.form.idEmpresa == 29) {
          document.body.className = document.body.className + ' carvip';
        }
        if (utils.verificaEmpresaBemProtege(this.form.idEmpresa)) {
          document.body.className = document.body.className + ' bp';
        }
        if (this.$route.query.cpfcnpj) {
          this.cpf = this.$route.query.cpfcnpj;
          this.form.cpfcnpj = this.$route.query.cpfcnpj;
          this.form.placa = this.$route.query.placa;
          this.selecionaTab(1);
        }

        if (this.$route.query.token && this.isMobile()) {
          localStorage.setItem('user-token', this.$route.query.token);
          window.location = `${window.location.origin}/AceiteCotacao/${this.$route.query.chave}`;
        }

        //this.mockSwipeList[0].push(this.template2);
        if (!this.form.idEmpresa) {
          service
            .getAll('g', {}, 'empresas/list')
            .then((res) => {
              res.data = decryptData(res.data);

              this.empresas = [{ value: '', text: 'Selecione' }];
              this.empresasAux = res.data;
              res.data.forEach((el) => {
                this.empresas.push({
                  value: el.idEmpresa,
                  text: el.nome,
                });
              });

              this.carregando = false;
              resolve();
            })
            .catch(() => {
              this.carregando = false;
              this.erroConexao =
                'Seu aplicativo parece estar offline, verifique sua conexao';
            });
        } else {
          service
            .getAll('g', {}, 'empresas/list/idEmpresa/' + this.form.idEmpresa)
            .then((res) => {
              res.data = decryptData(res.data);

              this.trocouLogo = true;
              this.logo = res.data[0].logo;
              localStorage.setItem('logo', this.logo);
              const favicon = document.getElementById('favicon');
              favicon.href = this.logo;

              document.title = res.data[0].nome;
              if (res.data[0].temaCor) {
                localStorage.setItem('temaCor', res.data[0].temaCor);
                var tema = JSON.parse(res.data[0].temaCor);
                this.temaCor = tema;
              }
              this.carregando = false;
              resolve();
            })
            .catch(() => {
              this.carregando = false;
              this.erroConexao =
                'Seu aplicativo parece estar offline, verifique sua conexao';
            });
        }
      });
    },
    exibirSenha() {
      if (this.tipoInputSenha == 'password') {
        this.tipoInputSenha = 'text';
        this.iconIputSenha = 'fa fa-eye-slash';
      } else {
        this.tipoInputSenha = 'password';
        this.iconIputSenha = 'fa fa-eye';
      }
    },
    getMessage(res) {
      if (res.data.celular || res.data.sentWhatsapp) {
        return `Sua nova senha foi enviada para o celular ${utils.escondeCelular(
          res.data.celular,
        )}`;
      }

      if (res.data.email) {
        return `Sua nova senha foi enviada para o email ${utils.escondeEmail(
          res.data.email,
        )}`;
      }
    },
    contagemRegressiva() {
      this.mostrarContador();
      localStorage.setItem('contando', 'contando');
      const diminuir1Segundo = setInterval(() => {
        this.contador--;
        this.contador === 0 && clearInterval(diminuir1Segundo);
        this.contador === 0 && localStorage.removeItem('contando');
        this.contador === 0 && this.mostrarContador();
      }, 1000);
    },
    mostrarContador() {
      this.contando = !this.contando;
      this.contador = 45;
    },
    verificarSeContando() {
      if (localStorage.getItem('contando')) {
        this.contagemRegressiva();
      }
    },
    countSentWhatsapp(res) {
      this.sentWhatsapp += res.data.sentWhatsapp ? 1 : 0;
    },
    keymonitorPlacaChassi(tipo) {
      if (tipo == 'placa') {
        this.chassi = null;
        if (this.placa.length == 8) {
          this.buscaPropostaPorPlacaChassi();
        } else {
          this.cotacaoEncontrada = false;
          this.btnDisabled = true;
        }
        return;
      }

      if (tipo == 'chassi') {
        this.placa = null;
        if (this.chassi.length == 17) {
          this.buscaPropostaPorPlacaChassi();
        } else {
          this.cotacaoEncontrada = false;
          this.btnDisabled = true;
        }
        return;
      }
    },
    buscaPropostaPorPlacaChassi() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.popToast();
          return;
        }

        this.carregando = true;

        const objSend = {
          placa: this.placa,
          chassi: this.chassi,
          idEmpresa: Number(this.idEmpresa),
        };

        service
          .post('CotacaoAberta', 'buscaPropostaPorPlacaChassi', objSend)
          .then((res) => {
            this.dadosProposta = res.data[0];
            this.chaveProposta = this.dadosProposta.chave;
            this.cotacaoEncontrada = true;
            this.btnDisabled = false;
            this.carregando = false;
          })
          .catch((e) => {
            this.cotacaoEncontrada = false;
            this.btnDisabled = true;
            this.carregando = false;
            this.$bvToast.toast(e, {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 3000,
            });
          });
      });
    },
    direcionaCotacaoExistente() {
      if (this.chaveProposta) {
        const urlOrigem = window.location.origin;

        //Para uso em QA
        if (urlOrigem.includes('qa.app')) {
          const novaUrl = urlOrigem.replace('qa.app', 'qa.adm');
          const redirect = `${novaUrl}/propostas/${this.chaveProposta}`;
          window.location = redirect;
          return redirect;
        }

        //Para uso em ambiente local caso a porta do APP seja 8080 e a do ADM seja 8081
        if (urlOrigem.includes('localhost:8080')) {
          const novaUrl = urlOrigem.replace('8080', '8081');
          const redirect = `${novaUrl}/propostas/${this.chaveProposta}`;
          window.location = redirect;
          return redirect;
        }

        //Para uso em ambiente local caso a porta do APP seja 8081 e a do ADM seja 8080
        if (urlOrigem.includes('localhost:8081')) {
          const novaUrl = urlOrigem.replace('8081', '8080');
          const redirect = `${novaUrl}/propostas/${this.chaveProposta}`;
          window.location = redirect;
          return redirect;
        }

        //Para uso em produção
        const novaUrl = urlOrigem.replace('app', 'adm');
        const redirect = `${novaUrl}/propostas/${this.chaveProposta}`;
        window.location = redirect;
        return redirect;
      }
    },
  },
  mounted() {
    this.$validator.localize('en', locale);
    // localStorage.removeItem('user-token');
    this.buscaDadosEmpresa();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.carregando {
  color: #fff;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 99999;
  text-align: center;
  padding-top: 50%;
}

.nav-link {
  display: block;
  padding: 0.5rem 0.5rem;
}

.text-xl {
  font-size: 1.25rem;
}

.logo {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  padding: 40px;
  margin: 25% 30px 20px;
}

.texto-placa {
  margin-top: 5px;
  font-size: 13px;
}
.link-senha {
  font-size: 13px;
  text-decoration: underline;
}
</style>
<style>
body.home {
  background-image: url('./../assets/images/5.png');
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-size: cover;
}

.headerModalCotacao {
  background-color: rgb(16, 72, 129);
  color: white;
  border-radius: 20px 20px 0 0;
  padding: 1.5rem;
}

#modalCotacao footer button {
  width: 100%;
}

#modalCotacao .modal-dialog .modal-content {
  border-radius: 20px;
}

#modalCotacao {
  font-size: 1.5rem;
  width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.placaChassiInput {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.cotacaoEncontradaTrue {
  font-size: 1rem;
  margin-left: 1rem;
  font-weight: bold;
  color: #28a745;
}

.cotacaoEncontradaFalse {
  font-size: 1rem;
  margin-left: 1rem;
  font-weight: bold;
  color: #dc3545;
}

body.associado {
  background-image: url('./../assets/images/4.png');
}

body.consultor {
  background-image: url('./../assets/images/2.png');
}

body.home.carvip {
  background-image: url('./../assets/images/29/5.png');
}
body.associado.carvip {
  background-image: url('./../assets/images/29/4.png');
}
body.consultor.carvip {
  background-image: url('./../assets/images/29/2.png');
}

body.home.bp {
  background-image: url('./../assets/images/bp-home.png');
}
body.associado.bp {
  background-image: url('./../assets/images/bp-associado.png');
}
body.consultor.bp {
  background-image: url('./../assets/images/bp-consultor.png');
}
#Senha,
#Senha2 {
  padding-right: 4rem !important;
}

#eyeButton {
  position: absolute;
  right: 60px;
  margin-top: 5px;
  opacity: 0.3;
  font-size: 20px;
}
</style>
<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
</style>
